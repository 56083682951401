import React, { Component } from "react";
import Slider from "react-slick";
import {testimonial1} from "../../../home/script";


class SassTestimonial extends Component{
    render(){
        let Data = [
            {
                thumb : '01',
                details: 'It’s obvious that @AeroLand is one of the best WordPress themes that can work perfectly well on both Gutenberg and Classic Editor.',
                title: 'Collin Mars',
                subtitle: 'Web designer'
            },
            {
                thumb : '02',
                details: '@AeroLand was unbelievably helpful to me when I need to find a responsive WordPress theme for my upcoming websites.  Love it.',
                title: 'Fatima Asrafi',
                subtitle: 'Web designer'
            },
            {
                thumb : '03',
                details: 'Working with @AeroLand is like having a family member who can fix everything. They know what you need, exactly when you need it.',
                title: 'Np Nipa',
                subtitle: 'Web designer'
            },
            {
                thumb : '04',
                details: 'Since I started using WordPress, I always felt tired of reading through the documentation. No more now, thanks to AeroLand.',
                title: 'Blanche Fields',
                subtitle: 'Web designer'
            }
        ];
        let DataList = Data.map((val, i) =>{
            return(
                <div className="testimonial_style--1" key={i}>
                    <div className="inner">
                        <div className="thumbnail">
                            <img src={require(`../../../assets/images/testimonial/client-${val.thumb}.png`)} alt="Testimonial Images"/>
                        </div>
                        <p className="details">{val.details}</p>
                        <h2 className="title">{val.title}</h2>
                        <span className="subtitle">{val.subtitle}</span>
                    </div>
                </div>
            )
        });
        return(
            <div className="testimonial-area sass-testimonial ptb--120 bg_color--5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h3 className="heading">Why do people love us?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mb--70">
                            <div className="testimonial-active-wrapper slick-default-dots">
                                <Slider {...testimonial1}>
                                    {DataList}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SassTestimonial;
