import React, { Component } from "react";


class PricingBoxThree extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row align-items-center">

                    {/* Start Single Pricing Table */}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--40">
                        <div className="pricing-table--3">
                            <div className="inner">
                                <div className="pricing-header">
                                    <div className="image">
                                        <img src={require('../../../assets/images/mokup/pricing-image-05.png')} alt="App Landing"/>
                                    </div>
                                    <h5 className="title">Free</h5>
                                    <div className="price-wrapper-inner">
                                        <h6 className="currency">$</h6>
                                        <h6 className="price">0</h6>
                                    </div>
                                </div>
                                <div className="pricing-button">
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">Get started</span>
                                    </button>
                                </div>

                                <div className="pricing-content">
                                    <ul className="list-style--1">
                                        <li><i className="fa fa-check"/> 03 projects</li>
                                        <li><i className="fa fa-check"/> Quality & Customer Experience</li>
                                        <li><i className="fa fa-check"/> <strong>Try for free, forever!</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Pricing Table */}

                    {/* Start Single Pricing Table */}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--40">
                        <div className="pricing-table--3 heighlight">
                            <div className="inner">
                                <div className="feature-mark">
                                    <span>Popular Choice</span>
                                </div>
                                <div className="pricing-header">
                                    <div className="image">
                                        <img src={require('../../../assets/images/mokup/pricing-image-06.png')} alt="App Landing"/>
                                    </div>
                                    <h5 className="title">PROFESSIONAL</h5>
                                    <div className="price-wrapper-inner">
                                        <h6 className="currency">$</h6>
                                        <h6 className="price">59</h6>
                                    </div>
                                </div>
                                <div className="pricing-button">
                                    <button className="ar-button style-flat secondary" type="submit">
                                        <span className="button-text">Get started</span>
                                    </button>
                                </div>

                                <div className="pricing-content">
                                    <ul className="list-style--1">
                                        <li><i className="fa fa-check"/> Unlimited project</li>
                                        <li><i className="fa fa-check"/> Power And Predictive Dialing</li>
                                        <li><i className="fa fa-check"/> Completely Gutenberg Ready</li>
                                        <li><i className="fa fa-check"/> 24/7 phone and email support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Pricing Table */}

                    {/* Start Single Pricing Table */}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--40">
                        <div className="pricing-table--3">
                            <div className="inner">
                                <div className="pricing-header">
                                    <div className="image">
                                        <img src={require('../../../assets/images/mokup/pricing-image-07.png')} alt="App Landing"/>
                                    </div>
                                    <h5 className="title">ADVANCED</h5>
                                    <div className="price-wrapper-inner">
                                        <h6 className="currency">$</h6>
                                        <h6 className="price">29</h6>
                                    </div>
                                </div>
                                <div className="pricing-button">
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">Get started</span>
                                    </button>
                                </div>

                                <div className="pricing-content">
                                    <ul className="list-style--1">
                                        <li><i className="fa fa-check"></i> Unlimited project</li>
                                        <li><i className="fa fa-check"></i> Power And Predictive Dialing</li>
                                        <li><i className="fa fa-check"></i> Completely Gutenberg Ready</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Pricing Table */}

                </div>
            </React.Fragment>
        )
    }
}
export default PricingBoxThree;
