import React, { Component , Fragment } from "react";
import {Helmet} from 'react-helmet';
import Breadcrumb from "../components/common/Breadcrumb";
import { connect } from "react-redux";




class PortfolioDetails extends Component{
    render(){
        const SinglePortfolio = this.props.post ? (
            <div className="portfolio-details-area">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="thumbnail">
                            <img className="w-100" src={require(`../assets/images/portfolio/portfolio-${this.props.post.thumbnail}.jpg`)} alt="Blog Images"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                        <div className="content">
                            <span className="category">{this.props.post.category}</span>
                            <h2 className="title">{this.props.post.title}</h2>
                            <p className="description">{this.props.post.description}</p>
                            <div className="portfolio-details">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="label">Date</td>
                                            <td>12th Jan, 2017</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Client</td>
                                            <td>Mudlab Muraz</td>
                                        </tr>
                                        <tr>
                                            <td className="label">Tags</td>
                                            <td><a href={`${process.env.PUBLIC_URL}/portfolio-grid`}>digital</a></td>
                                        </tr>
                                        <tr>
                                            <td className="label">Share</td>
                                            <td>
                                                <ul className="social-icon">
                                                    <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                                    <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                                </ul>   
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ): (
            <div className="text-center">Loading Post</div>
        )

        return(
            <Fragment>
                <Helmet>
                    <title>Portfolio Betails || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>
                
                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Portfolio Betails'} />
                {/* End Breadcrumb Area */}

                <div className="portfolio-details-area ptb--120">
                    <div className="container">
                        { SinglePortfolio }
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state , ownProps) => {
    let id = ownProps.match.params.post_id;
    return{
        post: state.portfolioReducers.find( post => post.id === id)
    }
}
export default connect(mapStateToProps)(PortfolioDetails);