import React, { Component } from "react";
import PropTypes from "prop-types";

class ServiceFour extends Component{
    render(){
        // Start Service Loop
        const ServiceList = this.props.ServiceData.map((item,i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={i}>
                    <div className="service-style--4">
                        <div className="thumbnail">
                            <img src={require(`../../assets/images/service/service-${item.images}.jpg`)} alt="Service Images"/>
                            <div className="action-title">
                                <h4>{item.title}</h4>
                            </div>
                        </div>
                        <div className="content">
                            <p>{item.description}</p>
                        </div>
                    </div>
                </div>
            ));
        return(
            <div className="row row--35 mt_dec--30">
                {ServiceList} 
            </div>
        )
    }
};

ServiceFour.PropsTypes = {
    ServiceData: PropTypes.array
};

ServiceFour.defaultProps = {
    ServiceData: [
        {
            images: "01",
            title: "Website &  app designing",
            description: "Our technical experts have a flair for developing clean-coded websites based on customers’ needs, global guidelines and trends.",
        },
        {
            images: "02",
            title: "Marketing campaign & PR	",
            description: "Our technical experts have a flair for developing clean-coded websites based on customers’ needs, global guidelines and trends.",
        },
        {
            images: "03",
            title: "Site ranking improvement",
            description: "Studies showed that the choice of appropriate keywords  for your sites' content, portfolios, posts help increase the site ranking.",
        },
    ]
};

export default ServiceFour;