import React, {Component} from "react";
import VideoButton from "../../common/VideoButton";

class DownloadNow extends Component {
    render() {
        let title = 'Download Now for Free',
            description = 'More than 35 social platforms are integrated into the app. Cross-platform sharing & displaying followers and likes are easy and convenient with just a few clicks.',
            images = '01'

        return (
            <div className="app-download-area appshowcase-download">
                <div className="app-download-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="thumbnail">
                                <img
                                    src={require(`../../../assets/images/mokup/app-showcase-image-${images}.png`)}
                                    alt="Images"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="app-download-inner">
                                <h3 className="font--35 fontWeight400 mb--30">{title}</h3>
                                <p className="mb--40">{description}</p>
                                <VideoButton ButtonStyle=""/>
                                <h6 className="next-arrowland title">What’s next in AeroLand? <a className="link-hover"
                                                                                                 href="/branding">Learn
                                    more</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DownloadNow;
