import React, { Component } from "react";
import Slider from "react-slick";
import {brand} from "../../home/script";


class Brand extends Component{

    render(){
        let Data = [
            {
                client : '01',
                client2: '01'
            },

            {
                client : '02',
                client2: '02'
            },

            {
                client : '03',
                client2: '03'
            },

            {
                client : '04',
                client2: '04'
            },

            {
                client : '05',
                client2: '05'
            },

            {
                client : '04',
                client2: '04'
            },

            {
                client : '05',
                client2: '05'
            },
            {
                client : '06',
                client2: '06'
            }
        ];

        return(
            <div className="brand-area ptb--80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="brand-activation">
                                <Slider {...brand}>
                                    {Data.map( (val, i) => (
                                        <div className="brand" key={i}>
                                            <a href="/">
                                                <div className="thumb2">
                                                    <img src={require(`../../assets/images/brand/client2-${val.client2}.png`)} alt="Client Images"/>
                                                </div>
                                                <div className="thumb">
                                                    <img src={require(`../../assets/images/brand/client-${val.client}.png`)} alt="Client Images"/>
                                                </div>
                                            </a>
                                        </div>
                                    ))}

                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Brand;

