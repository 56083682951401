import React, { Component } from "react";

class ContactOne extends Component{
    render(){
        return(
            <div className="contact-form-wrapper contact-form-one">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="contact-form-inner">
                                <div className="section-title mb--50">
                                    <h2 className="mb--10">Let’s cooperate!</h2>
                                    <p className="font--18">Let’s become partners to build a better future for today.</p>
                                </div>
                                <form className="contact-form--1">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label htmlFor="name"></label> 
                                            <input 
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Name"
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label htmlFor="email"></label> 
                                            <input 
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email" 
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <label htmlFor="subject"></label> 
                                            <input 
                                            type="subject"
                                            name="subject"
                                            id="subject"
                                            placeholder="Subject" 
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <label htmlFor="message"></label> 
                                            <textarea 
                                            type="message"
                                            name="message"
                                            id="message"
                                            placeholder="Message" >
                                            </textarea>
                                        </div>
                                        <div className="col-lg-12">
                                            <button className="ar-button style-flat" type="submit">
                                                <span className="button-text">Send message</span>
                                            </button>
                                        </div> 
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="thumbnail mt_md--30 mt_sm--30">
                                <img className="w-100" src={require('../../../assets/images/aeroland/contact-form-image-01.png')} alt="Contact Images"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;
