import React, { Component } from "react";

class CalltoActionFour extends Component{
    render(){
        return(
            <div className="wrapper ptb--110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <div className="content mb--20">
                                    <h2 className="mb--10 text-white">SEO marketing app on your mobile!</h2>
                                    <h5 className="text-white fontWeight400 mb--35">The power is in your hands now.</h5>
                                    <div className="button-group">
                                        <button className="button-image" type="submit">
                                            <img src={require('../../../assets/images/mokup/aeroland-button-app-store.jpg')} alt="Logo Images" />
                                        </button>
                                        <button className="button-image" type="submit">
                                            <img src={require('../../../assets/images/mokup/aeroland-button-google-play.jpg')} alt="Logo Images" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CalltoActionFour;