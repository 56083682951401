import React, { Component } from "react";

const SectionTitle = (props) => (
    <div className="section-title--3 text-center">
        <span>{props.subtitle}</span>
        <h2 className="fontWeight400 font--40">{props.title}</h2>
    </div>
)

let Gallery = [
    {
        grid: 'grid-width-2',
        images: '01',
        title: 'Aeroland app',
    },
    {
        grid: '',
        images: '02',
        title: 'Aeroland app',
    },
    {
        grid: '',
        images: '03',
        title: 'Aeroland app',
    },
    {
        grid: 'grid-width-2',
        images: '04',
        title: 'Aeroland app',
    },

]


class DgGallery extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="dg-gallery-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle subtitle="Featured projects" title="The efficiency we focus closely on" />
                            </div>
                        </div>
                    </div>
                    <div className="container mt--40 mt_sm--30 mt_md--40">
                        <div className="row">
                            <div className="col-lg-12 dg-gallery-list">
                                {Gallery.map((val , index) => (
                                    // Start Single Gallery
                                    <div key={index} className={`item-grid ${val.grid}`}>
                                        <a href={`${process.env.PUBLIC_URL}/portfolio-details`}>
                                            <div className="thumbnail">
                                                <img src={require(`../../../assets/images/gallery/dg-gallery-${val.images}.jpg`)} alt="App Landing"/>
                                            </div>
                                            <div className="hover-action">
                                                <h3 className="title">{val.title}</h3>
                                                <h3 className="read-more-button">Read project <span className="fa fa-arrow-right"></span></h3>
                                            </div>
                                        </a>
                                    </div>
                                    // End Single Gallery
                                ))}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="gallery-button mt--70 text-center">
                                    <button className="ar-button style-flat solid-style" type="submit">
                                        <span className="button-text">View more</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default DgGallery;




