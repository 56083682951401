import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BoxIconFive extends Component{
    render(){
        let Data = [
            {
                icon: '01',
                title: 'Connect ideas',
                desc: 'Consider this app a platform for connecting people from everywhere based on their preferences.'
            },

            {
                icon: '02',
                title: 'Make schedules',
                desc: 'An app that is designed for making plans, writing notes, record ideas and remind of your schedules.'
            },

            {
                icon: '03',
                title: 'Manage time',
                desc: 'Controlling your daily tasks, assign and discuss on projects, checking deadlines and set time to relax.'
            },

            {
                icon: '04',
                title: 'Working mobile',
                desc: 'It s easy to switch between different devices while keeping your data safe with secure login.'
            },

            {
                icon: '05',
                title: 'Quick account access',
                desc: 'Access to your account can be granted with the assistance from a fully automated fingerprint scanner.'
            },

            {
                icon: '06',
                title: 'Online credit services',
                desc: 'Our automated machines programmed for clients to carry out online credit check or other services.'
            }
        ]

        let DataList = Data.map((val , i) => {
            return(
                <Col className="mt--30" lg="6" md="6" sm="12" xs="12" key={i}>
                    <div className="box-icon--5">
                        <div className="icon">
                            <img src={require(`../../../assets/images/box-img/payment-box-icon-${val.icon}.png`)} alt="App Landing"/>
                        </div>
                        <div className="content">
                            <h5 className="heading">{val.title}</h5>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </Col>    
            )
        })
        return(
            <Row>
                {DataList}
            </Row>
        )
    }
}

export default BoxIconFive;