import React, { Component } from "react";
import CircleBox from "../../iconbox/CircleBox";
import Iconbox from "../../iconbox/Iconbox";
import { FaCheck } from "react-icons/fa";

class AllAppShowcase extends Component{
    render(){
        let 
        ServiceTitle = 'The 3-step process';
        
        return (
            <div className="app-showcase-area">
                {/* Start Curve Style */}
                <div className="curve-wrapper">
                    <div className="space-height--1"/>
                    <div className="center_curve bottom">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100">
                            <path d="M 0 0 Q 50 200 100 0"/>
                        </svg>
                    </div>
                    <CircleBox number="02" />
                    <div className="space-height--2"/>
                </div>
                {/* End Curve Style */}
                <div className="app-showcase-wrapper">
                    {/* Start Service Area */}
                    <div className="service-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--90">
                                        <h3 className="heading mb--30 fontWeight600">{ServiceTitle}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Iconbox />
                    </div>

                    <div className="allapp-showcase">
                        {/* Start Showcase Area */}
                        <div className="showcase showcase--1 pt--120 pt_sm--40 pt_md--40">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5 col-xl-5 col-md-5">
                                        <div className="thumbnail text-left text-xl-right">
                                            <img src={require('../../../assets/images/mokup/app-showcase-image-03.jpg')} alt="App Landing"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-5 offset-xl-1 col-md-7 mt_sm--40">
                                        <div className="showcase-inner">
                                            <span className="subtitle">01 – LOGIN SCREEN</span>
                                            <h3 className="title">Everything starts with the login screen</h3>
                                            <p>The design of a login screen is intended to give users a quick and secure way to access their personal account. Bot detection algorithm is available for selection.</p>
                                            <button className="ar-button style-flat" type="submit">
                                                <span className="button-text">Download now</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Showcase Area */}
                        
                        {/* Start Showcase Area */}
                        <div className="showcase showcase--2 pt--120 pt_sm--40 pt_md--40">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-xl-5 col-md-7 mt_sm--40 order-2 order-lg-1">
                                        <div className="showcase-inner">
                                            <span className="subtitle">02 – COMMUNICATE WITH FRIENDS</span>
                                            <h3 className="title">A Collection of <br /> Advanced Features</h3>
                                            <ul className="list">
                                                <li><FaCheck /> <span>User-friendly, modern and intuitive</span></li>
                                                <li><FaCheck /> <span>Updated mobile design & features</span></li>
                                                <li><FaCheck /> <span>Beautiful icons, typography and images</span></li>
                                                <li><FaCheck /> <span>Extendable premium customer support</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-7 col-md-5 order-1 order-lg-2">
                                        <div className="thumbnail text-left text-lg-right">
                                            <img src={require('../../../assets/images/mokup/app-showcase-image-04.png')} alt="App Landing"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Showcase Area */}

                        {/* Start Showcase Area */}
                        <div className="showcase showcase--3 pt--120 pt_sm--40 pt_md--40">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-xl-7 col-md-5">
                                        <div className="thumbnail text-left text-lg-right">
                                            <img src={require('../../../assets/images/mokup/app-showcase-image-05.png')} alt="App Landing"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-5 col-md-7 mt_sm--40">
                                        <div className="showcase-inner">
                                            <span className="subtitle">03-SOCIAL SHARE</span>
                                            <h3 className="title">Creative App Display</h3>
                                            <p>More than 35 social platforms are integrated into the app. Cross-platform sharing & displaying followers and likes are easy and convenient with just a few clicks.</p>
                                            <button className="ar-button style-flat" type="submit">
                                                <span className="button-text">Download now</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Showcase Area */}
                    </div>
                </div>
                {/* Start Curve Style */}
                <div className="curve-wrapper">
                    <div className="space-height--2"></div>
                    <div className="center_curve top">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100">
                            <path d="M 0 0 Q 50 200 100 0"></path>
                        </svg>
                    </div>
                    <CircleBox number="04" />
                    <div className="space-height--2"></div>
                </div>
                {/* End Curve Style */}
            </div>
        )
    }
}

export default AllAppShowcase;
