import React, { Component , Fragment } from "react";
import {Helmet} from 'react-helmet';
import Breadcrumb from "../components/common/Breadcrumb";
import { BLogComment } from "../home/data";
import { connect } from "react-redux";



class BlogDetails extends Component{
    render(){

        const SinglePost = this.props.post ? (
            <div className="single-post-details">
                <div className="thumbnail">
                    <img className="w-100" src={require(`../assets/images/blog/blog-${this.props.post.thumbnail}.jpg`)} alt="Blog Images"/>
                </div>
                <div className="content-wrapper">
                    <div className="content">
                        <span className="category">{this.props.post.category}</span>
                        <h2>{this.props.post.title}</h2>
                        <div className="post-meta">
                            <div className="meta-info">
                                <img src={require(`../assets/images/blog/clint-${this.props.post.blogMeta.authorThumb}.png`)} alt="Author"/>
                                <h6 className="author">{this.props.post.blogMeta.authorName}</h6>
                            </div>
                            <div className="date">
                                <span>{this.props.post.blogMeta.PostDate}</span>
                            </div>
                        </div>
                    </div>
                    

                    <div className="blog-description">
                        <p>Creative jobs website and networking platform The Dots has launched the “bias blocker”, a new browsing mode that stops employers being able to see candidates’ photos, names, education and employment history, on the basis that they should be judged on the “quality of their work” only. Will tools like this help to build a more diverse design workforce?</p>
                        <blockquote>
                            <p>We’re on a mission to build a better future where technology creates good jobs for everyone. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse moles dolore eu feugiat..</p>
                        </blockquote>
                        <p>Regardless of our gender, race, religion, cultural beliefs or education, all of us are biased in some way. This no doubt seeps into recruitment – whether we are subconsciously looking for people we relate to, or we are actively going against this to try to diversify the workplace, personal feelings and opinion are making their way into the hiring process.</p>

                        <p>Positive discrimination – giving an advantage to those from minority backgrounds or discriminated groups to put them on a level playing field with others – can only be a good thing. It increases the diversity of people, and therefore the diversity of ideas, in the workplace.</p>

                        <div className="blog-post-share">
                            <div className="post-share-left">
                                <ul className="tagcloud">
                                    <li><a href="/blog-classic/:post_id">designer</a></li>
                                    <li><a href="/blog-classic/:post_id">font</a></li>
                                    <li><a href="/blog-classic/:post_id">mookup</a></li>
                                </ul>
                            </div>
                            <div className="post-share-right">
                                <span>Share this post</span>
                                <div className="post-social-inner">
                                    <ul className="social-icon large-icon">
                                        <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                        <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Start Related Post */}
                        <div className="related-post-wrapper">
                            <h4 className="post-title">Related Posts</h4>
                            <div className="related-post">
                                <div className="inner">
                                    <span>Web Design</span>
                                    <h3>Design Festival Offset Dublin in 2019</h3>
                                </div>
                            </div>
                        </div>
                        {/* End Related Post */}

                    </div>

                    <div className="blog-comment">
                        <h4 className="post-title">Comments (4)</h4>
                        {/* Start Single Comment */}
                        {BLogComment.map((value , i) => (
                            <div className={`single-comment ${value.CommentToComment}`} key={i}>
                                <div className="thumbnail">
                                    <img src={require(`../assets/images/blog/comment/comment-${value.images}.png`)} alt="Images" />
                                </div>
                                <div className="comment-content">
                                    <div className="heading">
                                        <h5>{value.title}</h5>
                                        <span>{value.commentDate}</span>
                                    </div>
                                    <p>{value.description}</p>
                                    <div className="reply-button">
                                        <button>Reply</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* End Single Comment */}
                    </div>
                    
                    {/* Start Comment Form Area */}
                    <div className="comment-form-area">
                        <h4 className="post-title">Leave your thought here</h4>
                        <div className="comment-form">
                            <form action="#">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="input-box">
                                            <input 
                                            type="text" 
                                            placeholder="Your Name *"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-box">
                                            <input 
                                            type="email" 
                                            placeholder="Your Email *"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            <textarea 
                                            type="text" 
                                            placeholder="Your Name *">
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="blog-button mt--30 text-center">
                                            <button className="ar-button style-flat" type="submit">
                                                <span className="button-text">Submit</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* End Comment Form Area */}
                </div>
            
            </div>
        ): (
            <div className="text-center">Loading Post</div>
        )

        return(
            <Fragment>
                <Helmet>
                    <title>Blog Betails || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>
                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Blog Betails'} />
                {/* End Breadcrumb Area */}
                <div className="blog-details-area ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                { SinglePost }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state , ownProps) => {
    let id = ownProps.match.params.post_id;
    return{
        post: state.blogReducers.find( post => post.id === id)
    }
}
export default connect(mapStateToProps)(BlogDetails);