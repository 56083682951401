import React, { Component } from "react";


class FooterTwo extends Component{
    render(){
        return(
            <footer className="footer-area footer-style--2 pt--120 pb--40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-inner text-center">
                                <div className="content mb--50">
                                    <h2 className="text-white fontWeight300 mb--20">App is available <strong>for free</strong> <br /> on Google Play & App Store</h2>
                                    <h6 className="font--18 text-white fontWeight300">We’ll help you achieve your marketing & business goals</h6>
                                </div>
                                <div className="digital-app-button">
                                    <a href="/sass"><img src={require('../../assets/images/mokup/aeroland-button-app-store.jpg')} alt="App landing" /></a>
                                    <a href="/sass"><img src={require('../../assets/images/mokup/aeroland-button-google-play.jpg')} alt="App landing" /></a>
                                </div>
                                <div className="footer-social-text mt--90 mt_sm--30 mt_md--30">
                                    <ul className="social-text-link">
                                        <li><a href="https://twitter.com/"><span>twitter</span></a></li>
                                        <li><a href="https://dribbble.com/"><span>dribbble</span></a></li>
                                        <li><a href="https://www.instagram.com/"><span>instagram</span></a></li>
                                        <li><a href="https://www.youtube.com/"><span>youtube</span></a></li>
                                        <li><a href="https://slack.com/"><span>slack</span></a></li>
                                    </ul>
                                </div>
                                <div className="copyright-list-inner mt--40">
                                    <ul className="copyright-list">
                                        <li>© {new Date().getFullYear()}, AeroLand. All Rights Reserved</li>
                                        <li>(+00) 123 567990</li>
                                        <li>contact@aeroland.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterTwo;
