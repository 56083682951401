import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-area">
        {/* Start Footer TOp */}
        <div className="footer-top ptb--80">
          <div className="container">
            <div className="row">
              {/* Start Single Widget */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget address">
                  <div className="inner">
                    <Link className="logo" to={`${process.env.PUBLIC_URL}/`}>
                      <img
                        src={require('../../assets/images/logo/logo.png')}
                        alt="App landing"
                      />
                    </Link>
                    <div className="addres">
                      <p>58 Howard Street San Francisco</p>
                      <p>
                        <a className="link-hover" href="/">
                          contact@aeroland.com
                        </a>
                      </p>
                      <p>
                        <a className="link-hover" href="/">
                          (+68)1221 09876
                        </a>
                      </p>
                    </div>
                    <div className="author">
                      <a className="link-hover" href="/">
                        www.aeroland.example.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget */}

              {/* Start Single Widget */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget what-we mt_mobile--40">
                  <h5 className="title">Who we are</h5>
                  <div className="inner">
                    <ul className="menu">
                      <li>
                        <a className="link-hover" href={`/`}>
                          About us
                        </a>
                      </li>
                      <li>
                        <a className="link-hover" href={`/`}>
                          Before you go
                        </a>
                      </li>
                      <li>
                        <a className="link-hover" href={`/`}>
                          Online check in
                        </a>
                      </li>
                      <li>
                        <a className="link-hover" href={`/`}>
                          FAQ
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget */}

              {/* Start Single Widget */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget quick-link mt_md--60 mt_sm--40">
                  <h5 className="title">Quick links</h5>
                  <div className="inner">
                    <ul className="menu">
                      <li>
                        <a className="link-hover" href={`/`}>
                          Pick up locations
                        </a>
                      </li>
                      <li>
                        <a className="link-hover" href={`/`}>
                          Terms of Payment
                        </a>
                      </li>
                      <li>
                        <a className="link-hover" href={`/`}>
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a className="link-hover" href={`/`}>
                          Where to Find Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget */}

              {/* Start Single Widget */}
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget quick-link mt_md--60 mt_sm--40">
                  <div className="inner">
                    <div className="tweet">
                      <div className="tweet-text">
                        <p>The white stripes Feature: </p>
                        <p>
                          <a href="/">bellaskova</a>
                        </p>
                        <p>
                          <a href="/">https://t.co/cO1QzqxiRx</a>
                        </p>
                        <p>
                          <a href="/">https://t.co/ufL7TS1yrr</a>
                        </p>
                      </div>
                      <div className="tweet-info">
                        <div className="tweet-inner">
                          <h6>Twitter</h6>
                          <span>June 25, 2020</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Single Widget */}
            </div>
          </div>
        </div>
        {/* End Footer TOp */}

        {/* Start Copyright Area */}
        <div className="copyright-area copyright-style--1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="copyright text-center text-md-left">
                  <p>{`© ${new Date().getFullYear()}, AeroLand. All Rights Reserved.`}</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="copyright-right text-center text-md-right mt_sm--20">
                  <ul className="social-share justify-content-end">
                    <li>
                      <a href="https://twitter.com">
                        <i className="fab fa-twitter"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://facebook.com">
                        <i className="fab fa-facebook-f"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com">
                        <i className="fab fa-instagram"/>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Copyright Area */}
      </footer>
    );
  }
}

export default Footer;
