import React, {Component} from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Feature extends Component {
    render() {
        let Data = [
            {
                icon: '01',
                title: 'Cross-browser Compatible Design',
                desc: 'Apps are built based on highly compatible design for all kinds of Internet browsers.'
            },
            {
                icon: '02',
                title: 'Highly Responsive Tools',
                desc: 'Themes are integrated with highly responsive tools for supporting site building work.'
            },
            {
                icon: '03',
                title: 'Completely Gutenberg Ready',
                desc: 'AeroLand is compatible with Gutenberg and still has Classic Editor for content editing.'
            },
        ]

        let DataList = Data.map((val, i) => {
            return (
                <Col className="mb--30 single-feature" xs="12" key={i}>
                    <div className="feature">
                        <div className="icon">
                            <img src={require(`../../assets/images/box-img/branding-analitc-${val.icon}.png`)} alt="App Landing"/>
                        </div>
                        <div className="content">
                            <h5 className="heading">{val.title}</h5>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </Col>
            )
        })
        return (
            <Row className="mt--30">
                {DataList}
            </Row>
        )
    }
}

export default Feature;
