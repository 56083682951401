import React,  { Component } from "react";


class AllFeatures extends Component{
    render(){
        let title =  'All Functional Features Included',
        description = 'We dream about building a mighty empire by designing responsive apps that inspire business initiatives as well as future forwarding visions. With simple guides, everything is easy!',
        buttonText = 'Learn more' ,
        nextText = 'What’s next in AeroLand?',
        nextButtonText = 'Learn more';
        return(
            <div className="wrapper">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img src={require('../../../assets/images/aeroland/app-landing-image-01.png')} alt="App Landing"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content">
                            <h2 className="text-white">{title}</h2>
                            <p>{description}</p>
                            <button className="ar-button style-flat" type="submit">
                                <span className="button-text">{buttonText}</span>
                            </button>
                            <h5 className="font--18 text-white">{nextText} <a className="color-4 link-hover" href="/">{nextButtonText}</a></h5>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AllFeatures;