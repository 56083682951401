import React, { Component , Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";



class Portfolio extends Component{
    render(){
        const { PortfolioList } = this.props;
        const PortfolioAll = PortfolioList.slice(this.props.startItem , this.props.col);
        return(
            <Fragment>
                <div className="row">
                    {PortfolioAll.map( port => (
                        <div className={`${this.props.SingleColumn} ${port.gridwidth2} ${port.gridheight2}`} key= {port.id}>
                            <div className={`${this.props.PortfolioStyle}`}>
                                <div className="thumbnail">
                                    <Link to={'/portfolio-grid/' + port.id}>
                                        <img className="w-100" src={require(`../../assets/images/portfolio/portfolio-${port.thumbnail}.jpg`)} alt="Blog Images"/>
                                    </Link>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <h4 className="title">
                                            {this.props.categoryShow === 'yes' ? <p className="category">{port.category}</p>:''}
                                            <Link to={'/portfolio-grid/' + port.id}>
                                                {port.title}
                                            </Link>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        PortfolioList: state.portfolioReducers
    }
}
export default connect(mapStateToProps)(Portfolio);


