import React, { Component } from "react";


class Chanters extends Component{
    render(){
        return (
            <div className="echanters-inner chanters-bg pt--100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <div className="thumbnail">
                                    <img src={require('../../../assets/images/aeroland/ebook-image-03.jpg')} alt="App Landing"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Chanters;
