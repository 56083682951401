import React, { Component } from "react";

class FooterThree extends Component{
    render(){
        return(
            <footer className="footer footer-style--3 footer-bg-images bg_color--7 pb--50">
                <div className="footer-top text-center">
                    <div className="thumbnail">
                        <img src={require('../../assets/images/mokup/payment-image-01.png')} alt="App Landing"/>
                    </div>
                </div>
                <div className="footer-content text-center plr_sm--20">
                    <h2 className="text-white">Download Our App Today!</h2>
                    <p className="text-white font--18 mb--60">App is available for free on Google Play & App Store</p>
                    <div className="button-group">
                        <button className="button-image" type="submit">
                            <img src={require('../../assets/images/mokup/aeroland-button-app-store.jpg')} alt="Logo Images" />
                        </button>
                        <button className="button-image" type="submit">
                            <img src={require('../../assets/images/mokup/aeroland-button-google-play.jpg')} alt="Logo Images" />
                        </button>
                    </div>
                    <div className="copyright-inner">
                        <ul className="content">
                            <li>© {new Date().getFullYear()}, AeroLand. All Rights Reserved</li>
                            <li>(+00) 123 567990</li>
                            <li>contact@aeroland.com</li>
                        </ul>
                        <div className="social-share-inner mt--50">
                            <ul className="social-icon large-icon text-white">
                                <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                                <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                                <li><a href="https://dribbble.com/"><i className="fab fa-dribbble"></i></a></li>
                                <li><a href="https://www.pinterest.com/"><i className="fab fa-pinterest"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="vc_row-separator center_curve top">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100"><path d="M 0 0 Q 50 200 100 0"></path>
                    </svg>
                </div>
            </footer>
        )
    }
}
export default FooterThree;