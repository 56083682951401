import React, { Component } from "react";
import { Link } from "react-router-dom";
import Brand from "../../../components/brand/Brand";

const Cooparation = (props) => {
    return(
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-8 col-sm-12">
                <div className="inner">
                    <h2 className="fontWeight400 font--40 mb--50 mb_sm--10 mb_md--10">{props.title}</h2>
                    <Link className="font--18 color-4 fontWeight600" to={`${process.env.PUBLIC_URL}/team`}>{props.button}</Link>
                </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 mt_sm--40">
                <div className="cooparation-btn text-left text-md-right">
                    <button className="ar-button style-flat" type="submit">
                        <span className="button-text">{props.joinbutton}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
class DgCooperation extends Component{
    render(){
        let thumbnail = require('../../../assets/images/gallery/digital-gallery-01.jpg'),
        thumbnail2 = require('../../../assets/images/gallery/digital-gallery-02.jpg');
        return(
            <div className="wrapper">
                <div className="cooparation-top mb--80 mb_md--50 mb_sm--40">
                    <div className="container">
                        <Cooparation title='Cooperation unifies the best teammates & create miracles.' button='View all team' joinbutton='Join Us' />
                    </div>
                </div>
                {/* Start Gallery Area */}
                <div className="dg-gallery-area">
                    <div className="container">

                        <div className="row gallery-activation">
                            <div className="col-lg-8">
                                <div className="gallery-thumbnail">
                                    <img className="w-100" src={thumbnail} alt="App Landing"/>
                                </div>
                            </div>
                            <div className="col-lg-4 mt_md--30 mt_sm--30">
                                <div className="gallery-thumbnail">
                                    <img className="w-100" src={thumbnail2} alt="App Landing"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* End Gallery Area */}

                {/* Start Brand Area */}
                <Brand />
                {/* End Brand Area */}
            </div>
        )
    }
}
export default DgCooperation;
