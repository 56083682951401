import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import { withTranslate } from 'react-redux-multilingual';

class HeaderOne extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        var elements = document.querySelectorAll('.has-dropdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <div>
                <header className="header header--transparent color--light">
                    <div className="header-wrapper">
                        <div className="branding">
                            <div className="logo">
                                <Link to={`${process.env.PUBLIC_URL}/`}>
                                    <img src={require('../../assets/images/logo/light-logo.png')} alt="App landing" />
                                </Link>
                            </div>
                        </div>
                        <div className="page-navigation">
                            <nav className="mainmenu-nav">
                                <ul className="mainmenu">
                                    <li className="has-dropdown"><a href="#home"><span>Home</span></a>
                                        <ul className="submenu">
                                            <li><Link to="/branding">branding</Link></li>
                                            <li><Link to="/sass">sass</Link></li>
                                            <li><Link to="/app-showcase">app showcase</Link></li>
                                            <li><Link to="/software">software</Link></li>
                                            <li><Link to="/startups">startups</Link></li>
                                            <li><Link to="/payment">payment</Link></li>
                                            <li><Link to="/app-landing">app landing</Link></li>
                                            <li><Link to="/digital-agency">digital agency</Link></li>
                                            <li><Link to="/ebook">ebook</Link></li>
                                            <li><Link to="/clinic">clinic</Link></li>
                                            <li><Link to="/digital-store">digital store</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-dropdown"><a href="#elemets"><span>Elements</span></a>
                                        <ul className="submenu">
                                            <li><Link to="/button">button</Link></li>
                                            <li><Link to="/accordion">accordion</Link></li>
                                            <li><Link to="/attribute-list">attribute list</Link></li>
                                            <li><Link to="/box-icon">box icon</Link></li>
                                            <li><Link to="/call-to-action">call to action</Link></li>
                                            <li><Link to="/dividers">dividers</Link></li>
                                            <li><Link to="/counters">counters</Link></li>
                                            <li><Link to="/gradation">gradation</Link></li>
                                            <li><Link to="/message-box">message box</Link></li>
                                            <li><Link to="/popup-video">popup video</Link></li>
                                            <li><Link to="/progress-bar">progress bar</Link></li>
                                            <li><Link to="/lists">lists</Link></li>
                                            <li><Link to="/social-share">social share</Link></li>
                                            <li><Link to="/gallery">gallery</Link></li>
                                            <li><Link to="/team">team</Link></li>
                                            <li><Link to="/pricing-box">pricing box</Link></li>
                                            <li><Link to="/tabs">tabs</Link></li>
                                            <li><Link to="/contact">contact</Link></li>
                                            <li><Link to="/testimonial">testimonial</Link></li>
                                            <li><Link to="/countdown">countdown</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-dropdown"><a href="#blog"><span>Blog</span></a>
                                        <ul className="submenu">
                                            <li><Link to="/blog-classic">blog classic</Link></li>
                                            <li><Link to="/blog-two-column">blog two column</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-dropdown"><a href="#portfolio"><span>Portfolio</span></a>
                                        <ul className="submenu">
                                            <li><Link to="/portfolio-grid">portfolio grid</Link></li>
                                            <li><Link to="/portfolio-grid-wide">portfolio grid wide</Link></li>
                                            <li><Link to="/portfolio-metro">portfolio metro</Link></li>
                                            <li><Link to="/portfolio-grid-caption">portfolio grid caption</Link></li>
                                            <li><Link to="/portfolio-custom-image">portfolio custom image</Link></li>
                                            <li><Link to="/portfolio-details">portfolio details</Link></li>
                                        </ul>
                                    </li>
                                    <li className="has-dropdown"><a href="#pages"><span>Pages</span></a>
                                        <ul className="submenu">
                                            <li><Link to="/contact">Contact Us</Link></li>
                                            <li><Link to="/not-found">404 Page</Link></li>
                                            <li><Link to="/maintenance">Maintenance</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <div className="header-links d-none d-xl-block">
                                <a className="links" href="/">Try Aeroland</a>  
                            </div>
                            <div className="header-button d-none d-lg-block">
                                <button className="ar-button style-white"><span className="button-text">Hire Us Now	</span></button>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

export default withTranslate(HeaderOne);